/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractDtoPaymentType = typeof ContractDtoPaymentType[keyof typeof ContractDtoPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractDtoPaymentType = {
  single: 'single',
  installments: 'installments',
} as const;
