/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BaseResourceCategoryOptionsDtoValidationType = typeof BaseResourceCategoryOptionsDtoValidationType[keyof typeof BaseResourceCategoryOptionsDtoValidationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseResourceCategoryOptionsDtoValidationType = {
  number: 'number',
  none: 'none',
  alphanumeric: 'alphanumeric',
} as const;
