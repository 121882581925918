/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

/**
 * Alignment style in vertical direction.
 */
export type FlexImageAllOfGravity = typeof FlexImageAllOfGravity[keyof typeof FlexImageAllOfGravity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexImageAllOfGravity = {
  top: 'top',
  bottom: 'bottom',
  center: 'center',
} as const;
