/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type StripePaymentDtoStripeObjectIdType = typeof StripePaymentDtoStripeObjectIdType[keyof typeof StripePaymentDtoStripeObjectIdType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripePaymentDtoStripeObjectIdType = {
  session: 'session',
  payment_intent: 'payment_intent',
  charge: 'charge',
  customer: 'customer',
  refund: 'refund',
} as const;
