/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexCarouselType = typeof FlexCarouselType[keyof typeof FlexCarouselType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexCarouselType = {
  carousel: 'carousel',
} as const;
