import { ButtonV2, LinkButton, themeV3 } from '@atomica.co/components';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import React from 'react';
import styled from 'styled-components';

interface P {
  downloadTemplate: () => void;
  downloadPlans: () => void;
}

export const ImportContractTemplateDownloadComponent: React.FC<P> = React.memo(props => {
  const { downloadTemplate, downloadPlans } = props;
  return (
    <>
      <Description>
        <span>アップロードするCSVファイルのテンプレートをダウンロードして、編集してください。CSVの編集方法は、</span>
        <LinkButton label='ヘルプ' onClick={() => (location.href = 'https://www.google.co.jp/')} />
        <span>を参照してください。</span>
      </Description>
      <ButtonWrapper>
        <ButtonV2
          type='tertiary'
          startIcon={<GetAppRoundedIcon />}
          label='テンプレートをダウンロード'
          onClick={downloadTemplate}
        />
        <ButtonV2
          type='tertiary'
          startIcon={<GetAppRoundedIcon />}
          label='契約プランをダウンロード'
          onClick={downloadPlans}
        />
      </ButtonWrapper>
    </>
  );
});

ImportContractTemplateDownloadComponent.displayName = 'ImportContractTemplateDownloadComponent';

const Description = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
`;
