/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ShotSubcategoryDtoQuantityInputType = typeof ShotSubcategoryDtoQuantityInputType[keyof typeof ShotSubcategoryDtoQuantityInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShotSubcategoryDtoQuantityInputType = {
  manual: 'manual',
  fixed: 'fixed',
} as const;
