import { Code } from '@atomica.co/types';
import { MenuDefCodeEnum } from '../enums/base-menu-enum';
import { Path } from '../router/Routes';

export const toMenuPath = (menuDefCode: Code | undefined): Path | undefined => {
  switch (menuDefCode) {
    case MenuDefCodeEnum.USERS:
      return Path.USER_SEARCH;
    case MenuDefCodeEnum.USERS_V2:
      return Path.USER_SEARCH_V2;
    case MenuDefCodeEnum.TODAYS_USERS:
      return Path.VISITORS;
    case MenuDefCodeEnum.RESERVATIONS:
      return Path.RESERVATION_LIST;
    case MenuDefCodeEnum.QUESTIONNAIRE:
      return Path.QUESTIONNAIRE_LIST;
    case MenuDefCodeEnum.CONTRACTS:
      return Path.CONTRACT_LIST;
    case MenuDefCodeEnum.CONTRACTS_V2:
      return Path.CONTRACT_LIST_V2;
    case MenuDefCodeEnum.BILLING:
      return Path.BILLING_LIST_V2;
    case MenuDefCodeEnum.WISHES:
      return Path.WISH_LIST;
    case MenuDefCodeEnum.EVENTS:
      return Path.EVENT_V2_LIST;
    case MenuDefCodeEnum.DATA_EXPORT:
      return Path.EXPORT;
    case MenuDefCodeEnum.SHOT_MASTER:
      return Path.SHOT_MASTER;
    case MenuDefCodeEnum.RESOURCE_MASTER:
      return Path.RESOURCE_MASTER;
    case MenuDefCodeEnum.MASTER:
      return Path.SHOT_MASTER;
    case MenuDefCodeEnum.CONSUMER_DROP_IN:
      return Path.DROP_IN;
    default:
      return;
  }
};
