/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexTextType = typeof FlexTextType[keyof typeof FlexTextType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexTextType = {
  text: 'text',
} as const;
