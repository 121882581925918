import { Key, Label } from '@atomica.co/types';

export type FileKind = 'contract' | 'contractDetails' | 'contractUsers';
export type Process = 'success' | 'failure' | 'unprocessed';

export const initializeFiles = {
  contract: null,
  contractDetails: null,
  contractUsers: null
};

export const initializeImportStatus: Record<FileKind, Process> = {
  contract: 'unprocessed',
  contractDetails: 'unprocessed',
  contractUsers: 'unprocessed'
};

export interface FileStatus {
  key: FileKind;
  label: string;
  status: Process;
}

export const FILE_TYPES: FileStatus[] = [
  { key: 'contract', label: '契約ファイル', status: 'unprocessed' },
  { key: 'contractDetails', label: '契約詳細ファイル', status: 'unprocessed' },
  { key: 'contractUsers', label: '契約参加者ファイル', status: 'unprocessed' }
];

export const FILE_SIZE_10MB = 10 * 1024 * 1024;
export const ERROR_FILE_TYPE = 'text/plain;charset=utf-8';

export type ContractImportType = 'contract' | 'contractUser';

export const CONTRACT_IMPORT_TYPE: Record<Key, ContractImportType> = {
  CONTACT: 'contract',
  CONTRACT_USER: 'contractUser'
};

export const CONTRACT_IMPORT_TYPE_LABELS: Record<Key, Label> = {
  [CONTRACT_IMPORT_TYPE.CONTACT]: '契約情報・契約明細',
  [CONTRACT_IMPORT_TYPE.CONTRACT_USER]: '契約参加者'
};
