/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractV2DtoContractorType = typeof ContractV2DtoContractorType[keyof typeof ContractV2DtoContractorType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractV2DtoContractorType = {
  person: 'person',
  entity: 'entity',
} as const;
