/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SaveUserBodyGender = typeof SaveUserBodyGender[keyof typeof SaveUserBodyGender];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaveUserBodyGender = {
  male: 'male',
  female: 'female',
  other: 'other',
} as const;
