/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ScheduledLineMessageDtoTimezone = typeof ScheduledLineMessageDtoTimezone[keyof typeof ScheduledLineMessageDtoTimezone];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScheduledLineMessageDtoTimezone = {
  UTC: 'UTC',
  'Asia/Tokyo': 'Asia/Tokyo',
} as const;
