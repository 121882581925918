import { ButtonV2, FileDropZone } from '@atomica.co/components';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import React, { useMemo } from 'react';
import { FILE_SIZE_10MB } from './import-contract-import-type';

interface P {
  onContractFileChange: (file: File) => Promise<boolean>;
  onContractDetailsFileChange: (file: File) => Promise<boolean>;
  onClick: () => void;
  files: {
    contract: File | null;
    contractDetails: File | null;
  };
  isLoading: boolean;
  maxSizeInBytes?: number;
}

export const ImportContractUploaderComponent: React.FC<P> = React.memo(props => {
  const {
    onContractFileChange,
    onContractDetailsFileChange,
    onClick,
    files,
    isLoading,
    maxSizeInBytes = FILE_SIZE_10MB
  } = props;

  const isDisabled = useMemo(() => {
    const hasRequiredFiles = files.contract !== null && files.contractDetails !== null;
    return !hasRequiredFiles || isLoading;
  }, [files, isLoading]);

  return (
    <>
      <FileDropZone
        fileType='csv'
        header='契約情報'
        onChange={(file: File) => onContractFileChange(file)}
        maxSizeInBytes={maxSizeInBytes}
      />
      <FileDropZone
        fileType='csv'
        header='契約明細'
        onChange={(file: File) => onContractDetailsFileChange(file)}
        maxSizeInBytes={maxSizeInBytes}
      />
      <ButtonV2
        type='primary'
        startIcon={<NoteAddOutlinedIcon />}
        label='ファイルを取り込む'
        onClick={onClick}
        loading={isLoading}
        disabled={isDisabled}
      />
    </>
  );
});

ImportContractUploaderComponent.displayName = 'ImportContractUploaderComponent';
