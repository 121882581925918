/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ShotCategoryDtoShotType = typeof ShotCategoryDtoShotType[keyof typeof ShotCategoryDtoShotType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShotCategoryDtoShotType = {
  free_space: 'free_space',
  conference: 'conference',
  other: 'other',
} as const;
