/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  FetchContractUsage200,
  FetchContractUsageParams,
  FetchContractV2ByUser200
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsContract = () => {
/**
 * ユーザーに紐づく契約V2を取得する
 * @summary ユーザーに紐づく契約V2を取得する
 */
const fetchContractV2ByUser = (
    baseId: string,
    userId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractV2ByUser200>(
      {url: `/user/base/${baseId}/user/${userId}/contract`, method: 'GET'
    },
      options);
    }
  /**
 * 契約利用状況を取得する
 * @summary 契約利用状況を取得する
 */
const fetchContractUsage = (
    baseId: string,
    userId: string,
    params?: FetchContractUsageParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractUsage200>(
      {url: `/user/base/${baseId}/user/${userId}/contract-usage`, method: 'GET',
        params
    },
      options);
    }
  return {fetchContractV2ByUser,fetchContractUsage}};
export type FetchContractV2ByUserResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractV2ByUser']>>>
export type FetchContractUsageResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractUsage']>>>
