/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  FetchContractPlansForAdmin200,
  ImportContractsFromCsvForAdmin200,
  ImportContractsFromCsvForAdminBody
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';
import type { BodyType } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsContract = () => {
/**
 * CSVから契約情報・契約詳細をインポートする
 * @summary CSVから契約情報・契約詳細をインポートする
 */
const importContractsFromCsvForAdmin = (
    baseId: string,
    importContractsFromCsvForAdminBody: BodyType<ImportContractsFromCsvForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {const formData = new FormData();
if(importContractsFromCsvForAdminBody.contractsCsv !== undefined) {
 formData.append('contractsCsv', importContractsFromCsvForAdminBody.contractsCsv)
 }
if(importContractsFromCsvForAdminBody.contractDetailsCsv !== undefined) {
 formData.append('contractDetailsCsv', importContractsFromCsvForAdminBody.contractDetailsCsv)
 }
formData.append('userId', importContractsFromCsvForAdminBody.userId)

      return privateAxiosInstance<ImportContractsFromCsvForAdmin200>(
      {url: `/admin/base/${baseId}/contracts/import`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  /**
 * 契約プランを取得する
 * @summary 契約プランを取得する
 */
const fetchContractPlansForAdmin = (
    baseId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractPlansForAdmin200>(
      {url: `/admin/base/${baseId}/contract-plans`, method: 'GET'
    },
      options);
    }
  return {importContractsFromCsvForAdmin,fetchContractPlansForAdmin}};
export type ImportContractsFromCsvForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['importContractsFromCsvForAdmin']>>>
export type FetchContractPlansForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractPlansForAdmin']>>>
