import { EnvDef } from '@atomica.co/utils';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import env from '../env/env';

if (String(env) === EnvDef.PRODUCTION) {
  datadogRum.init({
    applicationId: '5d44145b-72f6-4d8c-a3f8-7c0c4118342f',
    clientToken: 'pubfa91d03fdb8253291e6b213f8b9b61db',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'ap1.datadoghq.com',
    service: 'knotplace',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: import.meta?.env?.FARM_CI_COMMIT_SHORT_SHA ?? 'unknown',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: ['https://asia-northeast1-atomica-apps-production.cloudfunctions.net', 'https://api.atomica.me']
  });

  datadogLogs.init({
    clientToken: 'pubfa91d03fdb8253291e6b213f8b9b61db',
    site: 'ap1.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
  });
}
