/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpacePricingDtoUsageType = typeof SpacePricingDtoUsageType[keyof typeof SpacePricingDtoUsageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpacePricingDtoUsageType = {
  reserve: 'reserve',
  drop_in: 'drop_in',
} as const;
