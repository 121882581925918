/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchResourceUsages200CardsItemSpaceCategory = typeof FetchResourceUsages200CardsItemSpaceCategory[keyof typeof FetchResourceUsages200CardsItemSpaceCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchResourceUsages200CardsItemSpaceCategory = {
  entrance: 'entrance',
  conference: 'conference',
  device: 'device',
  free_space: 'free_space',
} as const;
