/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchAccessesVersion = typeof FetchAccessesVersion[keyof typeof FetchAccessesVersion];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchAccessesVersion = {
  v1: 'v1',
  v2: 'v2',
} as const;
