/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type TempSpaceReservationsDtoTaxDiv = typeof TempSpaceReservationsDtoTaxDiv[keyof typeof TempSpaceReservationsDtoTaxDiv];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TempSpaceReservationsDtoTaxDiv = {
  exempt: 'exempt',
  included: 'included',
  excluded: 'excluded',
} as const;
