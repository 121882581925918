/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  FetchTodayUsersForAdmin200,
  SearchUsersForWishForAdmin200,
  SearchUsersForWishForAdminParams
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsUser = () => {
/**
 * 本日の利用者情報を取得する
 * @summary 本日の利用者情報を取得する
 */
const fetchTodayUsersForAdmin = (
    baseId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchTodayUsersForAdmin200>(
      {url: `/admin/base/${baseId}/user/today`, method: 'GET'
    },
      options);
    }
  /**
 * ユーザー情報を検索する
 * @summary ユーザー情報を検索する
 */
const searchUsersForWishForAdmin = (
    baseId: string,
    params: SearchUsersForWishForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchUsersForWishForAdmin200>(
      {url: `/admin/base/${baseId}/user/search`, method: 'GET',
        params
    },
      options);
    }
  return {fetchTodayUsersForAdmin,searchUsersForWishForAdmin}};
export type FetchTodayUsersForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['fetchTodayUsersForAdmin']>>>
export type SearchUsersForWishForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['searchUsersForWishForAdmin']>>>
