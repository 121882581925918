/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type CancelPolicyDtoCancelFeeType = typeof CancelPolicyDtoCancelFeeType[keyof typeof CancelPolicyDtoCancelFeeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancelPolicyDtoCancelFeeType = {
  percent: 'percent',
  amount: 'amount',
} as const;
