export const csvConverter = {
  /**
   * オブジェクトの配列をCSV形式の文字列に変換する関数
   * @param objArray 変換対象のオブジェクト配列
   * @returns CSV形式の文字列
   */
  convertToCSV: <T extends Record<string, unknown>>(objArray: T[]): string => {
    if (objArray.length === 0) {
      return '';
    }
    const headers = Object.keys(objArray[0]);
    const headerRow = headers.join(',');

    const rows = objArray.map(obj => {
      return headers
        .map(header => {
          let value = obj[header] === null ? '' : obj[header]?.toString() || '';
          if (value.includes(',') || value.includes('"') || value.includes('\n')) {
            value = `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(',');
    });
    return headerRow + '\n' + rows.join('\n');
  }
};
