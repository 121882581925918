/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexBubbleAllOfSize = typeof FlexBubbleAllOfSize[keyof typeof FlexBubbleAllOfSize];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexBubbleAllOfSize = {
  nano: 'nano',
  micro: 'micro',
  deca: 'deca',
  hecto: 'hecto',
  kilo: 'kilo',
  mega: 'mega',
  giga: 'giga',
} as const;
