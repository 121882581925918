/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractOptionV2DtoQuantityInputType = typeof ContractOptionV2DtoQuantityInputType[keyof typeof ContractOptionV2DtoQuantityInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractOptionV2DtoQuantityInputType = {
  manual: 'manual',
  fixed: 'fixed',
  number_of_users: 'number_of_users',
  count_of_selected_options: 'count_of_selected_options',
} as const;
