/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexBoxAllOfPosition = typeof FlexBoxAllOfPosition[keyof typeof FlexBoxAllOfPosition];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexBoxAllOfPosition = {
  relative: 'relative',
  absolute: 'absolute',
} as const;
