export const HEADER_HEIGHT = 64;

export const MOBILE_MIN_WIDTH = 320;

export const MOBILE_MAX_WIDTH = 450;

export const DRAWER_WIDTH = 280;

export const CONSOLE_FOOTER_HEIGHT = 48;

export const CONSUMER_FOOTER_HEIGHT = 60;

export const IMAGE_UPLOAD_OPTIONS = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 1080
};

// ToFix: ハードコーディングを避けるために、DBから取得できるようにしたい
export const ANNOUNCEMENT_URL = 'https://www.notion.so/knotPLACE-b8fcfe7d585946b99e0cc91ad7d40dd9';
