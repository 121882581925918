/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BillingDetailV2DtoTaxDiv = typeof BillingDetailV2DtoTaxDiv[keyof typeof BillingDetailV2DtoTaxDiv];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingDetailV2DtoTaxDiv = {
  exempt: 'exempt',
  included: 'included',
  excluded: 'excluded',
} as const;
