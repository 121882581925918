/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type CameraActionType = typeof CameraActionType[keyof typeof CameraActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CameraActionType = {
  camera: 'camera',
} as const;
