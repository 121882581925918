/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BillingV2DtoInvoiceDeliveryMethod = typeof BillingV2DtoInvoiceDeliveryMethod[keyof typeof BillingV2DtoInvoiceDeliveryMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingV2DtoInvoiceDeliveryMethod = {
  post: 'post',
  email: 'email',
  none: 'none',
} as const;
