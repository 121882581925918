/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexTextAllOfWeight = typeof FlexTextAllOfWeight[keyof typeof FlexTextAllOfWeight];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexTextAllOfWeight = {
  regular: 'regular',
  bold: 'bold',
} as const;
