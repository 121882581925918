import { ContractAction } from '../enums/contract-v2-enum';
import { Path } from '../router/Routes';

export const CONTRACT_NO_DIGITS = 5;

export const MASTER_SEARCH_LIMIT = 10;

export const CONTRACT_DETAIL_PATHS: Path[] = [
  Path.CONTRACT_DETAILS_V2_DETAIL,
  Path.CONTRACT_DETAILS_V2_BILLING,
  Path.CONTRACT_DETAILS_V2_MEMBER,
  Path.CONTRACT_DETAILS_V2_LOGS
];

export const CONTRACT_ACTION_OPTIONS = Object.values(ContractAction).filter(
  action => action !== ContractAction.OPERATE_ALL
);

export const MAX_ENTITIY_NAME_LENGTH = 50;
