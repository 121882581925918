/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceReservationDtoEngineStatus = typeof SpaceReservationDtoEngineStatus[keyof typeof SpaceReservationDtoEngineStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceReservationDtoEngineStatus = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_MINUS_1: -1,
} as const;
