/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  ImportContractUsersFromCsvForAdmin200,
  ImportContractUsersFromCsvForAdminBody
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';
import type { BodyType } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getContractUser = () => {
/**
 * 契約メンバーをインポートする
 * @summary 契約メンバーをインポートする
 */
const importContractUsersFromCsvForAdmin = (
    importContractUsersFromCsvForAdminBody: BodyType<ImportContractUsersFromCsvForAdminBody>,
    baseId?: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {const formData = new FormData();
if(importContractUsersFromCsvForAdminBody.contractUsersCsv !== undefined) {
 formData.append('contractUsersCsv', importContractUsersFromCsvForAdminBody.contractUsersCsv)
 }

      return privateAxiosInstance<ImportContractUsersFromCsvForAdmin200>(
      {url: `/admin/base/${baseId}/contract-users/import`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  return {importContractUsersFromCsvForAdmin}};
export type ImportContractUsersFromCsvForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getContractUser>['importContractUsersFromCsvForAdmin']>>>
