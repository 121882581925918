/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractV2DtoPaymentMethod = typeof ContractV2DtoPaymentMethod[keyof typeof ContractV2DtoPaymentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractV2DtoPaymentMethod = {
  credit_card: 'credit_card',
  bank_transfer: 'bank_transfer',
  invoice: 'invoice',
  cash: 'cash',
} as const;
