/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

/**
 * The display style of the image if the aspect ratio of the image and that specified by the aspectRatio property do not match.

 */
export type FlexImageAllOfAspectMode = typeof FlexImageAllOfAspectMode[keyof typeof FlexImageAllOfAspectMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexImageAllOfAspectMode = {
  fit: 'fit',
  cover: 'cover',
} as const;
