import { PriceUtils, TaxDiv } from '@atomica.co/irori';
import { toTaxExcluded, toTaxIncluded } from '@atomica.co/utils';

interface CalcPriceProps {
  unitPrice: number;
  quentity: number;
  taxDiv: TaxDiv;
  taxRate?: number;
}

export default class PriceService {
  public static calcTaxExcludedPrice = (args: CalcPriceProps): number => {
    const { unitPrice, taxDiv, taxRate = 0, quentity } = args;
    const price = PriceUtils.applyAmountPrice(unitPrice, quentity);
    return taxDiv === TaxDiv.INCLUDED ? toTaxExcluded(price, taxRate) : price;
  };

  public static calcTaxIncludedPrice = (args: {
    unitPrice: number;
    quentity: number;
    taxDiv: TaxDiv;
    taxRate?: number;
  }): number => {
    const { unitPrice, taxDiv, taxRate = 0, quentity } = args;
    const price = PriceUtils.applyAmountPrice(unitPrice, quentity);
    return taxDiv === TaxDiv.EXCLUDED ? toTaxIncluded(price, taxRate) : price;
  };

  public static getTotalPricesAndTaxPrice = (args: CalcPriceProps) => {
    const taxExcludedTotalPrice = this.calcTaxExcludedPrice(args);
    const taxIncludedTotalPrice = this.calcTaxIncludedPrice(args);
    const taxPrice = taxIncludedTotalPrice - taxExcludedTotalPrice;
    return { taxExcludedTotalPrice, taxIncludedTotalPrice, taxPrice };
  };
}
