/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BaseEnabledFunctionsDtoToggleCode = typeof BaseEnabledFunctionsDtoToggleCode[keyof typeof BaseEnabledFunctionsDtoToggleCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseEnabledFunctionsDtoToggleCode = {
  'resource-authority': 'resource-authority',
  'user-identification': 'user-identification',
  'user-point': 'user-point',
  'user-rank': 'user-rank',
  'use-workhub-pass': 'use-workhub-pass',
  'not-send-message-v2': 'not-send-message-v2',
  'use-send-grid-version': 'use-send-grid-version',
  'use-space-checkout': 'use-space-checkout',
  'use-space-reserve-extension': 'use-space-reserve-extension',
  'use-space-order-period': 'use-space-order-period',
  'use-payment': 'use-payment',
  'use-contract-payment': 'use-contract-payment',
  'edit-contract-payment': 'edit-contract-payment',
  'use-google-calendar-attendees': 'use-google-calendar-attendees',
  'force-browser': 'force-browser',
  'access-control': 'access-control',
  'refactor-temp-space-reservations': 'refactor-temp-space-reservations',
  'use-master': 'use-master',
  'use-resource-master': 'use-resource-master',
  'use-reservation-space-payment': 'use-reservation-space-payment',
  'use-profile-v3': 'use-profile-v3',
  'use-members': 'use-members',
  'use-room-entry-exit-history-in-user-detail': 'use-room-entry-exit-history-in-user-detail',
  'use-join-screen': 'use-join-screen',
  'use-jerry': 'use-jerry',
  'use-totoro': 'use-totoro',
  'use-payment-stripe': 'use-payment-stripe',
  'use-payment-saison': 'use-payment-saison',
  'use-new-denso-qrcode': 'use-new-denso-qrcode',
  'send-questionnaire-answer-thanks-message': 'send-questionnaire-answer-thanks-message',
  'use-announcement': 'use-announcement',
  'use-reservation-control-equipment-edit': 'use-reservation-control-equipment-edit',
} as const;
