/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type IdentificationDefDtoIdentificationDefCode = typeof IdentificationDefDtoIdentificationDefCode[keyof typeof IdentificationDefDtoIdentificationDefCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentificationDefDtoIdentificationDefCode = {
  passport: 'passport',
  'my-number-card': 'my-number-card',
  'driving-licence': 'driving-licence',
  'insurance-card': 'insurance-card',
  'residence-card': 'residence-card',
  other: 'other',
} as const;
