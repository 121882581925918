/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  FetchUserByExternalId200,
  SaveUser200,
  SaveUserBody
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';
import type { BodyType } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsUser = () => {
/**
 * ExternalIdに紐づくユーザー情報を取得する
 * @summary ExternalIdに紐づくユーザー情報を取得する
 */
const fetchUserByExternalId = (
    
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchUserByExternalId200>(
      {url: `/user/user`, method: 'GET'
    },
      options);
    }
  /**
 * ユーザー情報を保存する
 * @summary ユーザー情報を保存する
 */
const saveUser = (
    saveUserBody: BodyType<SaveUserBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveUser200>(
      {url: `/user/user`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveUserBody
    },
      options);
    }
  return {fetchUserByExternalId,saveUser}};
export type FetchUserByExternalIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['fetchUserByExternalId']>>>
export type SaveUserResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['saveUser']>>>
