/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

/**
 * Alignment style in horizontal direction.

 */
export type FlexImageAllOfAlign = typeof FlexImageAllOfAlign[keyof typeof FlexImageAllOfAlign];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexImageAllOfAlign = {
  start: 'start',
  end: 'end',
  center: 'center',
} as const;
