/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type MessageActionType = typeof MessageActionType[keyof typeof MessageActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageActionType = {
  message: 'message',
} as const;
