/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchResourceUsages200CardsItemSpaceManagement = typeof FetchResourceUsages200CardsItemSpaceManagement[keyof typeof FetchResourceUsages200CardsItemSpaceManagement];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchResourceUsages200CardsItemSpaceManagement = {
  bitkey: 'bitkey',
  denso: 'denso',
} as const;
