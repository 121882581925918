/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchUsersForWishForAdminIdentificationsItem = typeof SearchUsersForWishForAdminIdentificationsItem[keyof typeof SearchUsersForWishForAdminIdentificationsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchUsersForWishForAdminIdentificationsItem = {
  Confirmed: 'Confirmed',
  Unconfirmed: 'Unconfirmed',
} as const;
