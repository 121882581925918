import { Name } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';

/**
 * エラーメッセージ作成
 * @param param0 { line: number; field: string; message: string }
 * @param convertField field en -> jp
 * @returns string
 */
export const createLineErrorMessage = (
  { line, field, message }: { line: number; field: string; message: string },
  convertField: (field: string) => string
) => {
  const convertedField = convertField(field);
  return `${line}行目 - ${convertedField} - ${message}`;
};
/**
 * 契約ユーザーImportにおけるメッセージ変換
 * @param field
 * @returns string
 */
export const convertContractUserFields = (field: Name): Name => {
  switch (field) {
    case 'invitationEmail':
      return 'メールアドレス';
    case 'contractId':
      return '契約ID';
    default:
      return EMPTY;
  }
};
