import { ButtonV2 } from '@atomica.co/components';
import { Add, Description } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

interface NoContractMessageProps {
  onClickManualCreate: () => void;
  onClickCsvImport: () => void;
}

const NoContractMessage: React.FC<NoContractMessageProps> = ({ onClickManualCreate, onClickCsvImport }) => {
  return (
    <Container data-testid='no-contract'>
      <Content>
        <Message>契約はまだ登録されていません。</Message>
        <ButtonContainer>
          <ButtonV2 type='tertiary' label='手入力で作成' startIcon={<Add />} onClick={onClickManualCreate} />
          <ButtonV2 type='tertiary' label='CSVで一括作成' startIcon={<Description />} onClick={onClickCsvImport} />
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default NoContractMessage;

const Container = styled.div`
  align-self: stretch;
  position: relative;
  height: 201px;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  padding: 64px 0;
  background: var(--semantic-token-container-neutral-white, white);
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--semantic-token-object-black, #2b2b2a);
  font-size: 14px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.25px;
  word-wrap: break-word;
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;
