/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BaseResourceCategoryOptionsDtoInputType = typeof BaseResourceCategoryOptionsDtoInputType[keyof typeof BaseResourceCategoryOptionsDtoInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseResourceCategoryOptionsDtoInputType = {
  'singleline-text': 'singleline-text',
  'multiline-text': 'multiline-text',
} as const;
