/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchResourcesForAdmin200ResourcesItemReservationType = typeof SearchResourcesForAdmin200ResourcesItemReservationType[keyof typeof SearchResourcesForAdmin200ResourcesItemReservationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchResourcesForAdmin200ResourcesItemReservationType = {
  時: '時',
  日: '日',
} as const;
