/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type MessageV2DtoMessageType = typeof MessageV2DtoMessageType[keyof typeof MessageV2DtoMessageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageV2DtoMessageType = {
  entry: 'entry',
  remind: 'remind',
  date_changed: 'date_changed',
  not_checkout: 'not_checkout',
} as const;
