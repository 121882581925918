/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceReservationDtoManuallyChangedStatus = typeof SpaceReservationDtoManuallyChangedStatus[keyof typeof SpaceReservationDtoManuallyChangedStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceReservationDtoManuallyChangedStatus = {
  canceled: 'canceled',
  rejected: 'rejected',
} as const;
