/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchUsersForWishForAdminUserTypesItem = typeof SearchUsersForWishForAdminUserTypesItem[keyof typeof SearchUsersForWishForAdminUserTypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchUsersForWishForAdminUserTypesItem = {
  admin: 'admin',
  member: 'member',
  shot_user: 'shot_user',
  wish_user: 'wish_user',
} as const;
