import { HttpResponseStatus } from '@atomica.co/utils';

export class NotFoundError extends Error {
  statusCode = HttpResponseStatus.NOT_FOUND;
  details = {
    errorCode: HttpResponseStatus.NOT_FOUND
  };
  severity = 'WARNING';
  constructor(message = '無効なURLが指定されました') {
    super(message);
    this.name = 'NotFoundError';
  }
}
