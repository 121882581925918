import { Label } from '@atomica.co/types';
import { USER_DETAIL_FEATURES, UserAction, UserDetailFeature } from '../enums/users-enum';
import { Path } from '../router/Routes';

export const USER_ACTION_OPTIONS = Object.values(UserAction).filter(action => action !== UserAction.OPERATE_ALL);

export const USER_DETAIL_FEATURE_PATH: Map<UserDetailFeature, Path> = new Map<UserDetailFeature, Path>([
  [USER_DETAIL_FEATURES.OVERVIEW, Path.USER_OVERVIEW],
  [USER_DETAIL_FEATURES.POINT_USAGE_LOG, Path.USER_POINT_USAGE_LOG],
  [USER_DETAIL_FEATURES.ROOM_ENTRY_EXIT_HISTORY, Path.USER_ROOM_ENTRY_EXIT_HISTORY]
]);

export const VisitorTab = {
  SHOT_USERS: 0,
  MEMBERS: 1,
  DROP_IN_USERS: 2
} as const;
export type VisitorTab = (typeof VisitorTab)[keyof typeof VisitorTab];
export const VISITOR_TAB_LABELS = {
  [VisitorTab.SHOT_USERS]: 'ショット利用者',
  [VisitorTab.MEMBERS]: '会員利用者',
  [VisitorTab.DROP_IN_USERS]: 'ドロップイン'
} as const satisfies Record<VisitorTab, Label>;
