/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type HttpErrorStatusCode = typeof HttpErrorStatusCode[keyof typeof HttpErrorStatusCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HttpErrorStatusCode = {
  NUMBER_400: 400,
  NUMBER_401: 401,
  NUMBER_402: 402,
  NUMBER_403: 403,
  NUMBER_404: 404,
  NUMBER_405: 405,
  NUMBER_406: 406,
  NUMBER_407: 407,
  NUMBER_408: 408,
  NUMBER_409: 409,
  NUMBER_410: 410,
  NUMBER_411: 411,
  NUMBER_412: 412,
  NUMBER_413: 413,
  NUMBER_414: 414,
  NUMBER_415: 415,
  NUMBER_416: 416,
  NUMBER_417: 417,
  NUMBER_418: 418,
  NUMBER_421: 421,
  NUMBER_422: 422,
  NUMBER_423: 423,
  NUMBER_424: 424,
  NUMBER_425: 425,
  NUMBER_426: 426,
  NUMBER_428: 428,
  NUMBER_429: 429,
  NUMBER_431: 431,
  NUMBER_451: 451,
  NUMBER_500: 500,
  NUMBER_501: 501,
  NUMBER_502: 502,
  NUMBER_503: 503,
  NUMBER_504: 504,
  NUMBER_505: 505,
  NUMBER_506: 506,
  NUMBER_507: 507,
  NUMBER_508: 508,
  NUMBER_510: 510,
  NUMBER_511: 511,
} as const;
