/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type PostbackActionAllOfInputOption = typeof PostbackActionAllOfInputOption[keyof typeof PostbackActionAllOfInputOption];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostbackActionAllOfInputOption = {
  closeRichMenu: 'closeRichMenu',
  openRichMenu: 'openRichMenu',
  openKeyboard: 'openKeyboard',
  openVoice: 'openVoice',
} as const;
