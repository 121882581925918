/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  FetchBase200,
  FetchBaseRelatedInfo200,
  FetchBaseRelatedInfoParams
} from '../../model'
import { publicAxiosInstance } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsBase = () => {
/**
 * 拠点情報を取得する
 * @summary 拠点情報を取得する
 */
const fetchBase = (
    baseCode: string,
 options?: SecondParameter<typeof publicAxiosInstance>,) => {
      return publicAxiosInstance<FetchBase200>(
      {url: `/public/base/${baseCode}`, method: 'GET'
    },
      options);
    }
  /**
 * 拠点に紐づく子テーブルの情報を一括取得する
 * @summary 拠点に紐づく子テーブルの情報を一括取得する
 */
const fetchBaseRelatedInfo = (
    baseId: string,
    params?: FetchBaseRelatedInfoParams,
 options?: SecondParameter<typeof publicAxiosInstance>,) => {
      return publicAxiosInstance<FetchBaseRelatedInfo200>(
      {url: `/public/base/${baseId}/relatedInfo`, method: 'GET',
        params
    },
      options);
    }
  return {fetchBase,fetchBaseRelatedInfo}};
export type FetchBaseResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsBase>['fetchBase']>>>
export type FetchBaseRelatedInfoResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsBase>['fetchBaseRelatedInfo']>>>
