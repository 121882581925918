export enum IconCodeEnum {
  FACE = 'face',
  GROUP = 'group',
  QUESTIONNAIRE = 'questionnaire',
  CONTRACT = 'contract',
  INVOICE = 'invoice',
  WISH = 'wish',
  EVENT = 'event',
  EXPORT = 'export',
  EVENT_LIST = 'event_list',
  HOME = 'home',
  SPACE_RESERVE = 'space_reserve',
  SHOT = 'shot',
  MASTER = 'master',
  MY_PAGE = 'my_page',
  MEMBERS = 'members',
  DROP_IN = 'drop_in',
  ANNOUNCEMENT = 'announcement'
}
