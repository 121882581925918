/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type WishDtoScope = typeof WishDtoScope[keyof typeof WishDtoScope];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WishDtoScope = {
  public: 'public',
  organization: 'organization',
  base: 'base',
} as const;
