/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BaseDtoEligibleUser = typeof BaseDtoEligibleUser[keyof typeof BaseDtoEligibleUser];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseDtoEligibleUser = {
  all: 'all',
  contracted: 'contracted',
} as const;
