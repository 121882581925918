/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type AccessV2DtoDirection = typeof AccessV2DtoDirection[keyof typeof AccessV2DtoDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessV2DtoDirection = {
  enter: 'enter',
  exit: 'exit',
} as const;
