/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexButtonType = typeof FlexButtonType[keyof typeof FlexButtonType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexButtonType = {
  button: 'button',
} as const;
