/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexBoxAllOfLayout = typeof FlexBoxAllOfLayout[keyof typeof FlexBoxAllOfLayout];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexBoxAllOfLayout = {
  horizontal: 'horizontal',
  vertical: 'vertical',
  baseline: 'baseline',
} as const;
