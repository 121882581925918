/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceDtoPublish = typeof SpaceDtoPublish[keyof typeof SpaceDtoPublish];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceDtoPublish = {
  open: 'open',
  authority_limited: 'authority_limited',
  contract_limited: 'contract_limited',
} as const;
