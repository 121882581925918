/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type DatetimePickerActionAllOfMode = typeof DatetimePickerActionAllOfMode[keyof typeof DatetimePickerActionAllOfMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DatetimePickerActionAllOfMode = {
  date: 'date',
  time: 'time',
  datetime: 'datetime',
} as const;
