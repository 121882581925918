/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BillingV2DtoBillingStatus = typeof BillingV2DtoBillingStatus[keyof typeof BillingV2DtoBillingStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingV2DtoBillingStatus = {
  unconfirmed: 'unconfirmed',
  confirmed: 'confirmed',
  payment_completed: 'payment_completed',
  payment_error: 'payment_error',
  deleted: 'deleted',
} as const;
