/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type AccessV2DtoKeyType = typeof AccessV2DtoKeyType[keyof typeof AccessV2DtoKeyType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessV2DtoKeyType = {
  smartphone: 'smartphone',
  nfcCard: 'nfcCard',
  qrCode: 'qrCode',
  faceRecognition: 'faceRecognition',
  reception: 'reception',
  wifi: 'wifi',
  hand: 'hand',
  auto: 'auto',
  passcode: 'passcode',
  autoFixedTime: 'autoFixedTime',
} as const;
