/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type EventParticipantDtoInflowSource = typeof EventParticipantDtoInflowSource[keyof typeof EventParticipantDtoInflowSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventParticipantDtoInflowSource = {
  continue: 'continue',
  staff: 'staff',
  instagram: 'instagram',
  twitter: 'twitter',
  facebook: 'facebook',
  flyer: 'flyer',
  friend: 'friend',
  line: 'line',
  other: 'other',
} as const;
