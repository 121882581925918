/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SaveAccessBodyAccessType = typeof SaveAccessBodyAccessType[keyof typeof SaveAccessBodyAccessType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaveAccessBodyAccessType = {
  in: 'in',
  out: 'out',
} as const;
