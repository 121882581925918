import { ButtonV2, FileDropZone } from '@atomica.co/components';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import React, { useMemo } from 'react';
import { FILE_SIZE_10MB } from './import-contract-import-type';

interface P {
  onContractUserFileChange: (file: File) => Promise<boolean>;
  onClick: () => void;
  files: {
    contractUsers: File | null;
  };
  isLoading: boolean;
  maxSizeInBytes?: number;
}

export const ImportContractUserUploaderComponent: React.FC<P> = React.memo(props => {
  const { onContractUserFileChange, onClick, files, isLoading, maxSizeInBytes = FILE_SIZE_10MB } = props;

  const isDisabled = useMemo(() => {
    return files.contractUsers === null || isLoading;
  }, [files, isLoading]);

  return (
    <>
      <FileDropZone
        fileType='csv'
        header='契約参加者'
        onChange={(file: File) => onContractUserFileChange(file)}
        maxSizeInBytes={maxSizeInBytes}
      />
      <ButtonV2
        type='primary'
        startIcon={<NoteAddOutlinedIcon />}
        label='ファイルを取り込む'
        onClick={onClick}
        loading={isLoading}
        disabled={isDisabled}
      />
    </>
  );
});

ImportContractUserUploaderComponent.displayName = 'ImportContractUserUploaderComponent';
