/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexSpanAllOfStyle = typeof FlexSpanAllOfStyle[keyof typeof FlexSpanAllOfStyle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexSpanAllOfStyle = {
  normal: 'normal',
  italic: 'italic',
} as const;
