/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpacePricingDtoPriceType = typeof SpacePricingDtoPriceType[keyof typeof SpacePricingDtoPriceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpacePricingDtoPriceType = {
  time: 'time',
  pack: 'pack',
} as const;
