/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractUserV2DtoInvitationStatus = typeof ContractUserV2DtoInvitationStatus[keyof typeof ContractUserV2DtoInvitationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractUserV2DtoInvitationStatus = {
  invited: 'invited',
  registered: 'registered',
  deleted: 'deleted',
} as const;
