/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ClipboardActionType = typeof ClipboardActionType[keyof typeof ClipboardActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClipboardActionType = {
  clipboard: 'clipboard',
} as const;
