/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BaseDtoSpaceManagement = typeof BaseDtoSpaceManagement[keyof typeof BaseDtoSpaceManagement];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseDtoSpaceManagement = {
  bitkey: 'bitkey',
  denso: 'denso',
} as const;
