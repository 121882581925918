/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ShotCategoryDtoQuantityInputType = typeof ShotCategoryDtoQuantityInputType[keyof typeof ShotCategoryDtoQuantityInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShotCategoryDtoQuantityInputType = {
  manual: 'manual',
  fixed: 'fixed',
} as const;
