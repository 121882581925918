/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type MessageV2DtoSendMethod = typeof MessageV2DtoSendMethod[keyof typeof MessageV2DtoSendMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageV2DtoSendMethod = {
  auto: 'auto',
  manual: 'manual',
  not_send: 'not_send',
} as const;
