import { Component, Image, themeV3 } from '@atomica.co/components';
import { Color, Name, URL } from '@atomica.co/types';
import React from 'react';
import styled from 'styled-components';
import mojaco from './../../assets/mojaco/mojaco_normal.png';

interface P {
  userName: Name;
  photoUrl?: URL;
  tagName?: Name;
  tagColor?: Color;
  onClick: () => void;
  children: React.ReactNode;
}

const BasePolaroid: React.FC<P> = React.memo(props => {
  const { userName, photoUrl, tagName, tagColor = '#ff7165', onClick, children } = props;

  return (
    <Component className='polaroid'>
      <Container onClick={onClick}>
        <Image width={208} height={208} src={photoUrl || mojaco} alt={userName} />
        <UserName>{userName}</UserName>

        <Content>{children}</Content>

        {tagName && <Tag background={tagColor}>{tagName}</Tag>}
      </Container>
    </Component>
  );
});

BasePolaroid.displayName = 'BasePolaroid';
export default BasePolaroid;

const Container = styled.div`
  position: relative;
  width: 240px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: unset;
  cursor: pointer;
  background: ${themeV3.mixins.v3.color.object.white};
  gap: ${themeV3.mixins.v3.spacing}px;
  margin-top: ${themeV3.mixins.v3.spacing * 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
`;

const UserName = styled.div`
  ${themeV3.mixins.v3.typography.title.large};
  color: ${themeV3.mixins.v3.color.object.gray};
`;

const Content = styled.div`
  width: 100%;
`;

const Tag = styled.div<{ background: Color }>`
  ${themeV3.mixins.v3.typography.label.medium};
  position: absolute;
  top: -16px;
  right: 0;
  height: 32px;
  display: grid;
  place-items: center;
  color: ${themeV3.mixins.v3.color.object.white};
  background: ${({ background }) => background};
  border-radius: 20px;
  padding: ${themeV3.mixins.v3.spacing / 2}px ${themeV3.mixins.v3.spacing * 1.5}px;
`;
