/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchResourcesForAdmin200ResourcesItemIsInvisible = typeof SearchResourcesForAdmin200ResourcesItemIsInvisible[keyof typeof SearchResourcesForAdmin200ResourcesItemIsInvisible];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchResourcesForAdmin200ResourcesItemIsInvisible = {
  表示する: '表示する',
  表示しない: '表示しない',
} as const;
