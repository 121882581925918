/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type QuestionnaireDtoStatus = typeof QuestionnaireDtoStatus[keyof typeof QuestionnaireDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionnaireDtoStatus = {
  draft: 'draft',
  private: 'private',
  released: 'released',
} as const;
