/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  FetchDropInsByDatesForAdmin200,
  FetchDropInsByDatesForAdminParams
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsSpace = () => {
/**
 * 指定した期間内のドロップイン情報を取得する
 * @summary 指定した期間内のドロップイン情報を取得する
 */
const fetchDropInsByDatesForAdmin = (
    baseId: string,
    params: FetchDropInsByDatesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchDropInsByDatesForAdmin200>(
      {url: `/admin/base/${baseId}/drop-in/date`, method: 'GET',
        params
    },
      options);
    }
  return {fetchDropInsByDatesForAdmin}};
export type FetchDropInsByDatesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchDropInsByDatesForAdmin']>>>
