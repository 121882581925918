/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SaveAccessBodyVersion = typeof SaveAccessBodyVersion[keyof typeof SaveAccessBodyVersion];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaveAccessBodyVersion = {
  v1: 'v1',
  v2: 'v2',
} as const;
