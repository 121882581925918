/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type AccessDtoAccessType = typeof AccessDtoAccessType[keyof typeof AccessDtoAccessType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessDtoAccessType = {
  in: 'in',
  out: 'out',
} as const;
