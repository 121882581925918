// 最初に読み込むモジュール
import '../../datadog/index';
// その他のモジュール
import { isUndefined } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import { isAuthGuarded as guarded } from '../../converters/path-converter';
import { NotFoundError } from '../../errors/NotFoundError';
import usePath from '../../redux/hooks/usePath';
import GuardedScreens from './guarded-screens/GuardedScreens';
import NoGuardedScreens from './no-guarded-screens/NoGuardedScreens';

const Root: React.FC = React.memo(() => {
  const { path, addBrowserBackEventListener, removeBrowserBackEventListener } = usePath();

  if (isUndefined(path)) throw new NotFoundError('このURLは無効です');

  const isAuthGuard = useMemo<boolean>(() => guarded(path), [path]);

  useEffect(() => {
    addBrowserBackEventListener();
    return () => removeBrowserBackEventListener();
  }, [addBrowserBackEventListener, removeBrowserBackEventListener]);

  return <>{isAuthGuard ? <GuardedScreens /> : <NoGuardedScreens />}</>;
});

Root.displayName = 'Root';
export default Root;
